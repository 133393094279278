import React, {FC} from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero";

const reasons = [
    {title: "Piiramatu arv domeene", text: "Our base offer is standards-oriented email hosting with full support for SMTP, IMAP4, POP3 as well webmail. No proprietary protocols or clients, ever."},
    {title: "Piiramatu arv emaili aadresse", text: "Our base offer is standards-oriented email hosting with full support for SMTP, IMAP4, POP3 as well webmail. No proprietary protocols or clients, ever."},
    {title: "Otsene tugi", text: "Our base offer is standards-oriented email hosting with full support for SMTP, IMAP4, POP3 as well webmail. No proprietary protocols or clients, ever."},
    {title: "Abistame seadistamisel", text: "Our base offer is standards-oriented email hosting with full support for SMTP, IMAP4, POP3 as well webmail. No proprietary protocols or clients, ever."},
    {title: "Kirjade edastamine", text: "Our base offer is standards-oriented email hosting with full support for SMTP, IMAP4, POP3 as well webmail. No proprietary protocols or clients, ever."},
    {title: "Raha tagasi garanteeritud", text: "Our base offer is standards-oriented email hosting with full support for SMTP, IMAP4, POP3 as well webmail. No proprietary protocols or clients, ever."},
    {title: "Reklaamivaba", text: "Our base offer is standards-oriented email hosting with full support for SMTP, IMAP4, POP3 as well webmail. No proprietary protocols or clients, ever."},
    {title: "Krüpteerimine", text: "Our base offer is standards-oriented email hosting with full support for SMTP, IMAP4, POP3 as well webmail. No proprietary protocols or clients, ever."},
];

interface ReasonProps {
    title: string;
    text: string;
}

const Reason:FC<ReasonProps> = ({title, text}) => {
    return <div>
        <h3 className="text-xl font-bold text-blue-900">
            {title}
        </h3>
        <p className="my-4 text-blue-900">{text}</p>
    </div>

}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <br />
    <Hero>
        <h1 className="text-4xl text-blue-900 text-center">Lihtne e-mail algajale ettevõtjale</h1>
        <p className="my-10 text-gray-700">Anna oma ettevõttele unikaalne email. Aitab juba sellest gmaili kasutamisest</p>
        <div className="flex w-1/3 space-x-6 items-center">
            <a className="rounded-lg bg-blue-900 py-2 px-4 text-white" href="#">
                <Link to="/proovi/">
                    Proovi tasuta
                </Link>
            </a>
            <a className="underline text-blue-700 font-semibold">
                <Link to="/hinnad/">
                    Vaata hindasid
                </Link>
            </a>
        </div>
    </Hero>
      <div className="my-10 grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-2 mx-4">
          {reasons.map((reason) => <Reason title={reason.title} text={reason.text} key={reason.title}/>)}
      </div>
  </Layout>
)

export default IndexPage
